import { createRouter, createWebHashHistory } from 'vue-router'

function isAuthenticated() {
  // Exemplo básico usando localStorage (adaptar conforme sua implementação)
  return localStorage.getItem('usuario') !== null;
}

const routes = [

  //ROUTES FOR ADMIN
  {
    path: '/',
    name: 'admin_login',
    component: () => import('../views/admin/login.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/cadastrar-empresa',
    name: 'admin_cadastro',
    component: () => import('../views/admin/cadastro.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/dashboard',
    name: 'admin_dashboard',
    component: () => import('../views/admin/dashboard.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/perfil',
    name: 'admin_profile',
    component: () => import('../views/admin/profile.vue'),
    meta: { requiresAuth: false } 
  },

  //SECRETARY
  {
    path: '/especialidades',
    name: 'especialidade',
    component: () => import('../views/admin/secretaria/especialidade_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/area-apoio',
    name: 'area-apoio',
    component: () => import('../views/admin/secretaria/area_apoio_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/jornadas',
    name: 'jornadas',
    component: () => import('../views/admin/secretaria/jornadas.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/temas-jornada/:id/',
    name: 'temas-jornada',
    component: () => import('../views/admin/secretaria/temas.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/fotos-jornada/:id/',
    name: 'fotos-jornada',
    component: () => import('../views/admin/secretaria/fotos_jornada.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/medicos',
    name: 'medico',
    component: () => import('../views/admin/secretaria/medico_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/especialistas-em-formacao',
    name: 'em_formacao',
    component: () => import('../views/admin/secretaria/em_formacao.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/artigos',
    name: 'artigos',
    component: () => import('../views/admin/secretaria/artigo.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/horarios',
    name: 'horarios',
    component: () => import('../views/admin/secretaria/horario_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/pacientes',
    name: 'pacientes',
    component: () => import('../views/admin/secretaria/paciente_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/agendamentos',
    name: 'agendamentos',
    component: () => import('../views/admin/secretaria/agendamento_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/directores',
    name: 'directores',
    component: () => import('../views/admin/secretaria/director_view.vue'),
    meta: { requiresAuth: true } 
  },

  //VITRINE
  {
    path: '/noticias',
    name: 'admin_vitrine_noticia',
    component: () => import('../views/admin/vitrine/noticia_view.vue')
  },
  {
    path: '/eventos',
    name: 'admin_vitrine_eventos',
    component: () => import('../views/admin/vitrine/evento_view.vue')
  },
  {
    path: '/videos',
    name: 'admin_vitrine_videos',
    component: () => import('../views/admin/vitrine/video_view.vue')
  },
  {
    path: '/galeria',
    name: 'admin_vitrine_galeria',
    component: () => import('../views/admin/vitrine/galeria_view.vue')
  },


  //FORMACAO
  {
    path: '/categoria-formacao',
    name: 'admin_formacao_categoria',
    component: () => import('../views/admin/formacao/categoria_formacao_view.vue')
  },
  {
    path: '/cursos',
    name: 'admin_formacao_cursos',
    component: () => import('../views/admin/formacao/curso_view.vue')
  },
  {
    path: '/anos',
    name: 'admin_formacao_anos',
    component: () => import('../views/admin/formacao/ano_view.vue')
  },
  {
    path: '/instituicoes',
    name: 'admin_formacao_escolas',
    component: () => import('../views/admin/formacao/escola_view.vue')
  },
  {
    path: '/total-formandos',
    name: 'admin_total_formandos',
    component: () => import('../views/admin/formacao/formandos_view.vue')
  },
  //AUTENTICAÇÃO
  {
    path: '/users',
    name: 'admin_autenticacao_users',
    component: () => import('../views/admin/autenticacao/users_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/autenticacao/logout',
    name: 'admin_autenticacao_logout',
    component: () => import('../views/admin/logout.vue'),
    meta: { requiresAuth: true } 
  },

  //UTILS
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound', 
    component: () => import('../views/404_view.vue'),
    meta: { requiresAuth: false } 
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// Guarda de navegação para verificar autenticação
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
